import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic from '../assets/images/bytovka1.jpeg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Generic - Forty by HTML5 UP</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Пример работ: <br/> Отделка вагонкой наружней части бытовки</h1>
                    </header>
                    <span className="image main"><img src={pic} alt="" /></span>
                    <p>Цена работ: <b>17'000 рублей</b></p>
                    <p>Время: <b>1 день</b></p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic